var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative"},[_vm._t("before"),_vm._v(" "),_vm._t("default",function(){return [_c(_vm.componentType,{tag:"component",staticClass:"cursor-pointer select-none text-left",class:[_vm.clickableTargetClasses, _vm.checkFocus ? '!outline-0' : ''],attrs:{"type":"button","tabindex":_vm.clickable ? 0 : -1},on:{"focusing":function($event){return _vm.$emit('focusing', $event)},"click":_vm.click,"mouseenter":_vm.hoverEnter,"mouseleave":_vm.hoverExit}},[_vm._t("target")],2)]}),_vm._v(" "),_c('CustomScroll',{directives:[{name:"clickaway",rawName:"v-clickaway",value:(_vm.clickawayEvent),expression:"clickawayEvent"}],ref:"dropdown",staticClass:"text-left dropdown-content z-10 py-3 shadow !absolute min-w-full mt-1 overflow-auto overflow-x-hidden duration-75",class:[
      _vm.leftAlign ? 'left-0' : 'right-0',
      _vm.useTheme ? 'bg-theme' : '',
      _vm.contentClasses,
      _vm.isActive
        ? 'opacity-100 ease-out'
        : 'opacity-0 pointer-events-none ease-in',
      _vm.animate ? 'duration-150' : _vm.up ? 'bottom-8' : '',
      _vm.animate && _vm.isActive ? (_vm.up ? 'bottom-8' : 'top-0') : '',
      _vm.animate && !_vm.isActive ? (_vm.up ? 'bottom-24' : '-top-24') : '',
    ],attrs:{"inert":!_vm.isActive}},[(_vm.opened)?_vm._t("content",null,{"close":_vm.closeEvent}):_vm._e()],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }