import {
  ApiClient,
  ApiPaginationParams,
  ApiPaginationResult,
} from '~/apiclient/apiclient'
import { capitalize } from '~/assets/ts/utils/strings'
import { UserCommentApi } from '~/apiclient/apicomments'
import { CaptionLanguages, MediaSet } from '~/models/media'
import { SeriesApi } from '~/apiclient/apiseries'
import { SpeakerApi } from '~/apiclient/apispeakers'
import { BroadcasterApi } from '~/apiclient/apibroadcasters'
import { HashtagApi } from '~/apiclient/apihashtags'
import { UserFeedSources } from '~/apiclient/apiusers'

export enum SermonSortOptions {
  NewestPublished = 'newest-published',
  Newest = 'newest',
  Oldest = 'oldest',
  PickDate = 'pickdate',
  Updated = 'updated',
  Title = 'title',
  Speaker = 'speaker',
  LastPlayed = 'lastplayed',
  Language = 'language',
  Event = 'event',
  Downloads = 'downloads',
  Series = 'series',
  Random = 'random',
  Added = 'added',
  CommentCount = 'commentcount',
  // Used for search contexts to allow default of sort by score
  Relevance = '',
  Bible = 'bible',
}

export type SermonApiEventType =
  | 'Audio Book'
  | 'Bible Study'
  | 'Camp Meeting'
  | 'Chapel Service'
  | 'Children'
  | 'Classic Audio'
  | 'Conference'
  | 'Current Events'
  | 'Debate'
  | 'Devotional'
  | 'Funeral Service'
  | 'Midweek Service'
  | 'Podcast'
  | 'Prayer Meeting'
  | 'Question &amp; Answer'
  | 'Radio Broadcast'
  | 'Sermon Clip'
  | 'Special Meeting'
  | 'Sunday - AM'
  | 'Sunday - PM'
  | 'Sunday Afternoon'
  | 'Sunday School'
  | 'Sunday Service'
  | 'Teaching'
  | 'Testimony'
  | 'TV Broadcast'
  | 'Video DVD'
  | 'Wedding'
  | 'Youth'
  | string

export interface SermonRequestOptions extends ApiPaginationParams {
  book?: string
  chapter?: string
  verse?: string
  broadcasterID?: string
  collectionID?: number
  denomination?: string
  eventType?: SermonApiEventType
  featured?: boolean
  followedOnly?: boolean
  hashtag?: string
  highlighted?: boolean
  includeWaveform?: boolean
  languageCode?: string
  lite?: boolean
  cache?: boolean
  publishedSinceTimestamp?: number
  preachedAfterTimestamp?: number
  preachedBeforeTimestamp?: number
  sermonsBeforeSermon?: string
  sermonsAfterSermon?: string
  requireVideo?: boolean
  requireAudio?: boolean
  requireTranscript?: boolean
  series?: string
  searchKeyword?: string
  sortBy?: SermonSortOptions
  /** @deprecated Use speakerID instead */
  speakerName?: string
  speakerID?: number
  sermonIDs?: string[]
  staffPick?: boolean
  /** @deprecated
   * This is only used by the Django embed urls via the fetchSermon middleware.
   * Please use the requireAudio/Video bools instead.
   * */
  type?: string
  year?: number
  month?: number
  day?: number
  audioMinDurationSeconds?: number
  audioMaxDurationSeconds?: number
}

export const DefaultSermonPageSize = 25
export const DefaultSermonVideoPageSize = 24

export interface SermonApi {
  // id: string
  sermonID: string
  bibleText: string
  broadcaster: BroadcasterApi
  commentCount: number
  captionLanguages: CaptionLanguages
  description: string
  displayTitle: string
  /** Audio only play count */
  downloadCount: number
  eventType: SermonApiEventType
  displayEventType: string
  favorite: boolean
  featureDate: string
  fullTitle: string
  keywords: string
  hasAudio: boolean
  hasVideo: boolean
  hashtags: HashtagApi[]
  lite: boolean
  media: MediaSet
  moreInfoText: string
  preachDate: string
  publishDate: string
  publishTimestamp: number
  series: SeriesApi
  speaker: SpeakerApi
  staffPick: boolean
  subtitle: string
  videoThumbnail: string
  videoThumbnailResizable: string
  waveformPeaksUrl: string
  videoDownloadCount: number
  audioDurationSeconds: number
  videoDurationSeconds: number
  feedItemSource?: UserFeedSources[]
  lastAudioAccessTimestamp?: number
}

export interface FeaturedSermonCampaignID {
  campaignID: string
}

export async function getFilteredSermonList(
  this: ApiClient,
  options: Partial<SermonRequestOptions>
): Promise<ApiPaginationResult<SermonApi>> {
  const results = [] as SermonApi[]
  let totalCount = 0
  let next
  let nodeType
  let nodeDisplayName

  // Initialize queryParams with selected options properties
  const queryParams: Record<string, any> = {
    ...options,
    // https://gitlab.sermonaudio.com/sa/project-management/main/-/wikis/APIs-for-SermonAudio-2.0#lite-model-objects
    cache: options.cache ?? true,
    liteBroadcaster: true,
    sermonIDs: options.sermonIDs ? options.sermonIDs.join(',') : undefined,
  }

  queryParams.requireAudio = options.requireAudio ?? true
  if (options.type) queryParams[`require${capitalize(options.type)}`] = true

  if (options.highlighted) {
    const { data } = await this.$axios.get(
      `node/broadcasters/${options.broadcasterID}/highlighted_sermons`,
      { params: queryParams }
    )
    data.audioSermon.id = data.audioSermon.sermonID
    results.push(data.audioSermon)
  } else {
    const { data } = await this.$axios.get(`node/sermons`, {
      params: { ...queryParams },
    })
    data.results.forEach((sermon: SermonApi) => {
      results.push(sermon)
    })
    totalCount = data.totalCount
    next = data.next
    nodeType = data.nodeType
    nodeDisplayName = data.nodeDisplayName
  }

  return {
    results,
    totalCount,
    next,
    nodeType,
    nodeDisplayName,
  }
}

export async function getWaveform(
  this: ApiClient,
  sermonID: string
): Promise<number[]> {
  const waveformUrl = `node/sermons/${sermonID}/waveform?peaksOnly=true`
  const { data } = await this.$axios.get(waveformUrl)
  return data
}

export async function getSermon(
  this: ApiClient,
  sermonID: string,
  lite = true
): Promise<Record<string, any>> {
  const { data } = await this.$axios.get(`node/sermons/${sermonID}`, {
    params: {
      // https://git.sermonaudio.com/project-management/main/-/wikis/APIs-for-SermonAudio-2.0#lite-model-objects
      liteBroadcaster: true,
      lite,
    },
  })
  return data
}

export async function getSermonComments(
  this: ApiClient,
  sermonID: string,
  page = 1,
  pageSize = 10
): Promise<ApiPaginationResult<UserCommentApi>> {
  const { data } = await this.$axios.get(`node/sermons/${sermonID}/comments`, {
    params: {
      page,
      pageSize,
    },
  })
  return data
}

export async function getFeaturedSermonCampaignID(
  this: ApiClient
): Promise<string> {
  const { data } = await this.$axios.get(
    'node/ads/manage/featuredsermons/current'
  )
  return data.campaignID
}
